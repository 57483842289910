<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    small?: boolean
  }>(),
  {
    small: false,
  }
)

const { getResourceUrl } = useResources()

const localePath = useLocalePath()

const { locale } = useI18n()

const repository = createProductSmartFiltersRepository()

const { data: smartFiltersData, status } = await useLazyAsyncData(
  'featuredSmartFilters_' + locale.value,
  () => repository.getFeaturedFilters()
)

const smartFilters = computed(() => smartFiltersData.value?.items || [])

const { data: categoriesData } = await useLazyAsyncData(
  'categorySmartFilters_' + locale.value,
  () => repository.getCategoryFilters()
)

const categories = computed(() => categoriesData.value?.items || [])

const productLinesRepository = createProductLineRepository()

const { data: topSeriesData } = await useLazyAsyncData('topSeries', () => {
  return Promise.all(
    topSeries.map((series) => {
      return productLinesRepository.getLineBySlug(series.slug)
    })
  )
})

const emit = defineEmits(['close'])
</script>

<template>
  <div class="pb-16 pt-10 @container lg:container">
    <div class="grid grid-cols-1 gap-16 @3xl:grid-cols-2 @6xl:grid-cols-3">
      <div
        class="flex flex-col justify-between border-black pr-16 @xl:border-r"
      >
        <div>
          <div class="group/title mb-10 w-fit">
            <NuxtLink
              :to="localePath({ name: 'products' })"
              class="font-pragmatica-ext flex items-center gap-2 text-xl font-bold no-underline"
              @click="emit('close')"
            >
              {{ $t('base.header.ourProducts') }}
              <IconDropdownArrow class="-rotate-90" />
            </NuxtLink>
            <span
              class="block h-[1px] max-w-0 bg-black transition-all duration-500 group-hover/title:max-w-full"
            ></span>
          </div>
          <div class="mb-4">
            <div v-for="category in categories" :key="category.nid">
              <div>
                <NuxtLink
                  :to="
                    localePath({
                      name: 'products-slug',
                      params: { slug: category.urlSlug },
                    })
                  "
                  class="group/category font-bold no-underline transition duration-300"
                  @click="emit('close')"
                >
                  <div class="flex flex-row text-lg">
                    <img
                      :height="35"
                      :width="35"
                      loading="lazy"
                      :src="getResourceUrl(category.icon?.url)"
                      v-if="false"
                    />
                    <div>
                      {{ category.title }}
                      <span
                        class="block h-[1px] max-w-0 bg-black transition-all duration-500 group-hover/category:max-w-full"
                      />
                    </div>
                  </div>
                </NuxtLink>
              </div>
            </div>
          </div>
        </div>
        <div>
          <NuxtLink
            :to="localePath({ name: 'products' })"
            class="group/products font-bold no-underline transition duration-300"
            :class="{ 'text-lg': props.small }"
            @click="emit('close')"
          >
            <UiButtonBlack :class="props.small ? 'p-1' : 'p-5'">
              {{ $t('base.products.productsLink') }}
            </UiButtonBlack>
          </NuxtLink>
        </div>
      </div>
      <div v-if="smartFilters.length">
        <p class="font-pragmatica-ext mb-10 text-xl font-bold">
          {{ $t('base.header.featured') }}
        </p>
        <div class="flex flex-col">
          <NuxtLink
            :to="
              localePath({
                name: 'products-slug',
                params: { slug: smartFilter.urlSlug },
              })
            "
            v-for="smartFilter in smartFilters"
            class="group/category font-bold no-underline transition duration-300"
            @click="emit('close')"
          >
            <div class="w-fit">
              {{ smartFilter.title }}
              <span
                class="block h-[1px] max-w-0 bg-black transition-all duration-500 group-hover/category:max-w-full"
              />
            </div>
          </NuxtLink>
        </div>
      </div>
      <div class="flex flex-col justify-between">
        <div>
          <NuxtLink
            :to="
              localePath({
                name: 'product-lines',
              })
            "
            class="group/category font-bold no-underline transition duration-300"
            @click="emit('close')"
          >
            <div class="font-pragmatica-ext mb-10 w-fit text-xl font-bold">
              <div class="flex items-center gap-2">
                {{ $t('base.header.productLines') }}
                <IconDropdownArrow class="-rotate-90" />
              </div>
              <span
                class="block h-[1px] max-w-0 bg-black transition-all duration-500 group-hover/category:max-w-full"
              />
            </div>
          </NuxtLink>
          <div class="mb-5">
            <NuxtLink
              :to="
                localePath({
                  name: 'products-slug',
                  params: { slug: series.urlSlug },
                })
              "
              v-for="series in topSeriesData"
              class="group/category font-bold no-underline transition duration-300"
              @click="emit('close')"
            >
              <div class="w-fit">
                {{ series.title }}
                <span
                  class="block h-[1px] max-w-0 bg-black transition-all duration-500 group-hover/category:max-w-full"
                />
              </div>
            </NuxtLink>
          </div>
        </div>
        <div>
          <NuxtLink
            :to="localePath({ name: 'product-lines' })"
            class="group/products font-bold no-underline transition duration-300"
            :class="{ 'text-lg': props.small }"
            @click="emit('close')"
          >
            <UiButtonBlackOutline :class="props.small ? 'p-1' : 'p-5'">
              {{ $t('base.header.seriesLink') }}
            </UiButtonBlackOutline>
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
