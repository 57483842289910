<script setup lang="ts">
const localePath = useLocalePath()
const { t } = useI18n()
</script>

<template>
  <UPopover
    class="hidden items-baseline lg:flex"
    :ui="{
      width: 'w-full left-0 !-ml-[8px] rounded-0',
      shadow: '',
      ring: '',
      rounded: '',
    }"
    mode="hover"
    :close-delay="300"
  >
    <NuxtLink
      :to="localePath({ name: 'products' })"
      class="no-underline transition duration-300"
    >
      <div class="group font-bold transition duration-300 xl:text-lg">
        <div class="flex items-center">
          <span class="whitespace-nowrap">
            {{ t('base.products.products') }}
          </span>
          <IconDropdownArrow class="ml-1" />
        </div>
        <span
          class="-mt-1 block h-[1px] max-w-0 bg-black transition-all duration-500 group-hover:max-w-full"
          :class="{
            '!max-w-full': $route.path.includes(
              localePath({ name: 'products' })
            ),
          }"
        />
      </div>
    </NuxtLink>
    <template #panel="{ close }">
      <UiProductCategoryMenu @close="close()" />
    </template>
  </UPopover>
  <div class="w-full lg:hidden">
    <NuxtLink
      :to="localePath({ name: 'products' })"
      class="font-bold no-underline transition duration-300 lg:mb-1"
    >
      <div
        class="group my-auto w-max font-bold transition duration-300 lg:text-lg"
      >
        {{ t('base.products.products') }}
        <span
          class="block h-[1px] max-w-0 bg-black transition-all duration-500 group-hover:max-w-full"
          :class="{
            '!max-w-full': $route.path.includes(
              localePath({ name: 'products' })
            ),
          }"
        />
      </div>
    </NuxtLink>
    <UiProductCategoryMenu small />
  </div>
</template>

<style scoped></style>
